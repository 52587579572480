package cz.vojtechhoranek.site

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.theme.colors.ColorScheme

object PrimaryColorScheme : ColorScheme {
    override val _50 = onPrimaryLight
    override val _100 = primaryContainerLight
    override val _200 = primaryDark
    override val _300 = primaryContainerDarkMediumContrast
    override val _400 = primaryContainerLightMediumContrast
    override val _500 = primaryLight
    override val _600 = primaryLightMediumContrast
    override val _700 = primaryContainerLightHighContrast
    override val _800 = primaryLightHighContrast
    override val _900 = secondaryLightHighContrast
}

object TertiaryColorScheme : ColorScheme {
    override val _50 = onTertiaryLight
    override val _100 = tertiaryContainerLight
    override val _200 = tertiaryDark
    override val _300 = tertiaryContainerDarkMediumContrast
    override val _400 = tertiaryContainerLightMediumContrast
    override val _500 = tertiaryLight
    override val _600 = tertiaryLightMediumContrast
    override val _700 = tertiaryContainerLightHighContrast
    override val _800 = tertiaryLightHighContrast
    override val _900 = onTertiaryContainerLight
}

val primaryLight = Color.argb(0xFF306A42)
val onPrimaryLight = Color.argb(0xFFFFFFFF)
val primaryContainerLight = Color.argb(0xFFB3F1BF)
val onPrimaryContainerLight = Color.argb(0xFF00210D)
val secondaryLight = Color.argb(0xFF506353)
val onSecondaryLight = Color.argb(0xFFFFFFFF)
val secondaryContainerLight = Color.argb(0xFFD2E8D3)
val onSecondaryContainerLight = Color.argb(0xFF0D1F12)
val tertiaryLight = Color.argb(0xFF3A656E)
val onTertiaryLight = Color.argb(0xFFFFFFFF)
val tertiaryContainerLight = Color.argb(0xFFBEEAF5)
val onTertiaryContainerLight = Color.argb(0xFF001F25)
val errorLight = Color.argb(0xFFBA1A1A)
val onErrorLight = Color.argb(0xFFFFFFFF)
val errorContainerLight = Color.argb(0xFFFFDAD6)
val onErrorContainerLight = Color.argb(0xFF410002)
val backgroundLight = Color.argb(0xFFF6FBF3)
val onBackgroundLight = Color.argb(0xFF181D18)
val surfaceLight = Color.argb(0xFFF6FBF3)
val onSurfaceLight = Color.argb(0xFF181D18)
val surfaceVariantLight = Color.argb(0xFFDDE5DA)
val onSurfaceVariantLight = Color.argb(0xFF414941)
val outlineLight = Color.argb(0xFF717971)
val outlineVariantLight = Color.argb(0xFFC1C9BF)
val scrimLight = Color.argb(0x51000000)
val inverseSurfaceLight = Color.argb(0xFF2D322D)
val inverseOnSurfaceLight = Color.argb(0xFFEEF2EA)
val inversePrimaryLight = Color.argb(0xFF97D5A4)
val surfaceDimLight = Color.argb(0xFFD7DBD4)
val surfaceBrightLight = Color.argb(0xFFF6FBF3)
val surfaceContainerLowestLight = Color.argb(0xFFFFFFFF)
val surfaceContainerLowLight = Color.argb(0xFFF0F5ED)
val surfaceContainerLight = Color.argb(0xFFEBEFE7)
val surfaceContainerHighLight = Color.argb(0xFFE5EAE2)
val surfaceContainerHighestLight = Color.argb(0xFFDFE4DC)

val primaryLightMediumContrast = Color.argb(0xFF0F4D29)
val onPrimaryLightMediumContrast = Color.argb(0xFFFFFFFF)
val primaryContainerLightMediumContrast = Color.argb(0xFF478157)
val onPrimaryContainerLightMediumContrast = Color.argb(0xFFFFFFFF)
val secondaryLightMediumContrast = Color.argb(0xFF344738)
val onSecondaryLightMediumContrast = Color.argb(0xFFFFFFFF)
val secondaryContainerLightMediumContrast = Color.argb(0xFF657A68)
val onSecondaryContainerLightMediumContrast = Color.argb(0xFFFFFFFF)
val tertiaryLightMediumContrast = Color.argb(0xFF1C4952)
val onTertiaryLightMediumContrast = Color.argb(0xFFFFFFFF)
val tertiaryContainerLightMediumContrast = Color.argb(0xFF517B85)
val onTertiaryContainerLightMediumContrast = Color.argb(0xFFFFFFFF)
val errorLightMediumContrast = Color.argb(0xFF8C0009)
val onErrorLightMediumContrast = Color.argb(0xFFFFFFFF)
val errorContainerLightMediumContrast = Color.argb(0xFFDA342E)
val onErrorContainerLightMediumContrast = Color.argb(0xFFFFFFFF)
val backgroundLightMediumContrast = Color.argb(0xFFF6FBF3)
val onBackgroundLightMediumContrast = Color.argb(0xFF181D18)
val surfaceLightMediumContrast = Color.argb(0xFFF6FBF3)
val onSurfaceLightMediumContrast = Color.argb(0xFF181D18)
val surfaceVariantLightMediumContrast = Color.argb(0xFFDDE5DA)
val onSurfaceVariantLightMediumContrast = Color.argb(0xFF3D453D)
val outlineLightMediumContrast = Color.argb(0xFF596159)
val outlineVariantLightMediumContrast = Color.argb(0xFF757D74)
val scrimLightMediumContrast = Color.argb(0xFF000000)
val inverseSurfaceLightMediumContrast = Color.argb(0xFF2D322D)
val inverseOnSurfaceLightMediumContrast = Color.argb(0xFFEEF2EA)
val inversePrimaryLightMediumContrast = Color.argb(0xFF97D5A4)
val surfaceDimLightMediumContrast = Color.argb(0xFFD7DBD4)
val surfaceBrightLightMediumContrast = Color.argb(0xFFF6FBF3)
val surfaceContainerLowestLightMediumContrast = Color.argb(0xFFFFFFFF)
val surfaceContainerLowLightMediumContrast = Color.argb(0xFFF0F5ED)
val surfaceContainerLightMediumContrast = Color.argb(0xFFEBEFE7)
val surfaceContainerHighLightMediumContrast = Color.argb(0xFFE5EAE2)
val surfaceContainerHighestLightMediumContrast = Color.argb(0xFFDFE4DC)

val primaryLightHighContrast = Color.argb(0xFF002911)
val onPrimaryLightHighContrast = Color.argb(0xFFFFFFFF)
val primaryContainerLightHighContrast = Color.argb(0xFF0F4D29)
val onPrimaryContainerLightHighContrast = Color.argb(0xFFFFFFFF)
val secondaryLightHighContrast = Color.argb(0xFF142619)
val onSecondaryLightHighContrast = Color.argb(0xFFFFFFFF)
val secondaryContainerLightHighContrast = Color.argb(0xFF344738)
val onSecondaryContainerLightHighContrast = Color.argb(0xFFFFFFFF)
val tertiaryLightHighContrast = Color.argb(0xFF00262E)
val onTertiaryLightHighContrast = Color.argb(0xFFFFFFFF)
val tertiaryContainerLightHighContrast = Color.argb(0xFF1C4952)
val onTertiaryContainerLightHighContrast = Color.argb(0xFFFFFFFF)
val errorLightHighContrast = Color.argb(0xFF4E0002)
val onErrorLightHighContrast = Color.argb(0xFFFFFFFF)
val errorContainerLightHighContrast = Color.argb(0xFF8C0009)
val onErrorContainerLightHighContrast = Color.argb(0xFFFFFFFF)
val backgroundLightHighContrast = Color.argb(0xFFF6FBF3)
val onBackgroundLightHighContrast = Color.argb(0xFF181D18)
val surfaceLightHighContrast = Color.argb(0xFFF6FBF3)
val onSurfaceLightHighContrast = Color.argb(0xFF000000)
val surfaceVariantLightHighContrast = Color.argb(0xFFDDE5DA)
val onSurfaceVariantLightHighContrast = Color.argb(0xFF1E261F)
val outlineLightHighContrast = Color.argb(0xFF3D453D)
val outlineVariantLightHighContrast = Color.argb(0xFF3D453D)
val scrimLightHighContrast = Color.argb(0xFF000000)
val inverseSurfaceLightHighContrast = Color.argb(0xFF2D322D)
val inverseOnSurfaceLightHighContrast = Color.argb(0xFFFFFFFF)
val inversePrimaryLightHighContrast = Color.argb(0xFFBCFBC8)
val surfaceDimLightHighContrast = Color.argb(0xFFD7DBD4)
val surfaceBrightLightHighContrast = Color.argb(0xFFF6FBF3)
val surfaceContainerLowestLightHighContrast = Color.argb(0xFFFFFFFF)
val surfaceContainerLowLightHighContrast = Color.argb(0xFFF0F5ED)
val surfaceContainerLightHighContrast = Color.argb(0xFFEBEFE7)
val surfaceContainerHighLightHighContrast = Color.argb(0xFFE5EAE2)
val surfaceContainerHighestLightHighContrast = Color.argb(0xFFDFE4DC)

val primaryDark = Color.argb(0xFF97D5A4)
val onPrimaryDark = Color.argb(0xFF00391A)
val primaryContainerDark = Color.argb(0xFF15512C)
val onPrimaryContainerDark = Color.argb(0xFFB3F1BF)
val secondaryDark = Color.argb(0xFFB7CCB8)
val onSecondaryDark = Color.argb(0xFF223526)
val secondaryContainerDark = Color.argb(0xFF384B3C)
val onSecondaryContainerDark = Color.argb(0xFFD2E8D3)
val tertiaryDark = Color.argb(0xFFA2CED9)
val onTertiaryDark = Color.argb(0xFF01363F)
val tertiaryContainerDark = Color.argb(0xFF204D56)
val onTertiaryContainerDark = Color.argb(0xFFBEEAF5)
val errorDark = Color.argb(0xFFFFB4AB)
val onErrorDark = Color.argb(0xFF690005)
val errorContainerDark = Color.argb(0xFF93000A)
val onErrorContainerDark = Color.argb(0xFFFFDAD6)
val backgroundDark = Color.argb(0xFF101510)
val onBackgroundDark = Color.argb(0xFFDFE4DC)
val surfaceDark = Color.argb(0xFF101510)
val onSurfaceDark = Color.argb(0xFFDFE4DC)
val surfaceVariantDark = Color.argb(0xFF414941)
val onSurfaceVariantDark = Color.argb(0xFFC1C9BF)
val outlineDark = Color.argb(0xFF8B938A)
val outlineVariantDark = Color.argb(0xFF414941)
val scrimDark = Color.argb(0x51000000)
val inverseSurfaceDark = Color.argb(0xFFDFE4DC)
val inverseOnSurfaceDark = Color.argb(0xFF2D322D)
val inversePrimaryDark = Color.argb(0xFF306A42)
val surfaceDimDark = Color.argb(0xFF101510)
val surfaceBrightDark = Color.argb(0xFF353A35)
val surfaceContainerLowestDark = Color.argb(0xFF0A0F0B)
val surfaceContainerLowDark = Color.argb(0xFF181D18)
val surfaceContainerDark = Color.argb(0xFF1C211C)
val surfaceContainerHighDark = Color.argb(0xFF262B26)
val surfaceContainerHighestDark = Color.argb(0xFF313631)

val primaryDarkMediumContrast = Color.argb(0xFF9CD9A8)
val onPrimaryDarkMediumContrast = Color.argb(0xFF001B09)
val primaryContainerDarkMediumContrast = Color.argb(0xFF639D72)
val onPrimaryContainerDarkMediumContrast = Color.argb(0xFF000000)
val secondaryDarkMediumContrast = Color.argb(0xFFBBD0BC)
val onSecondaryDarkMediumContrast = Color.argb(0xFF081A0D)
val secondaryContainerDarkMediumContrast = Color.argb(0xFF819683)
val onSecondaryContainerDarkMediumContrast = Color.argb(0xFF000000)
val tertiaryDarkMediumContrast = Color.argb(0xFFA6D2DD)
val onTertiaryDarkMediumContrast = Color.argb(0xFF00191F)
val tertiaryContainerDarkMediumContrast = Color.argb(0xFF6D97A2)
val onTertiaryContainerDarkMediumContrast = Color.argb(0xFF000000)
val errorDarkMediumContrast = Color.argb(0xFFFFBAB1)
val onErrorDarkMediumContrast = Color.argb(0xFF370001)
val errorContainerDarkMediumContrast = Color.argb(0xFFFF5449)
val onErrorContainerDarkMediumContrast = Color.argb(0xFF000000)
val backgroundDarkMediumContrast = Color.argb(0xFF101510)
val onBackgroundDarkMediumContrast = Color.argb(0xFFDFE4DC)
val surfaceDarkMediumContrast = Color.argb(0xFF101510)
val onSurfaceDarkMediumContrast = Color.argb(0xFFF8FCF4)
val surfaceVariantDarkMediumContrast = Color.argb(0xFF414941)
val onSurfaceVariantDarkMediumContrast = Color.argb(0xFFC5CDC3)
val outlineDarkMediumContrast = Color.argb(0xFF9DA59C)
val outlineVariantDarkMediumContrast = Color.argb(0xFF7D857D)
val scrimDarkMediumContrast = Color.argb(0xFF000000)
val inverseSurfaceDarkMediumContrast = Color.argb(0xFFDFE4DC)
val inverseOnSurfaceDarkMediumContrast = Color.argb(0xFF262B26)
val inversePrimaryDarkMediumContrast = Color.argb(0xFF16522E)
val surfaceDimDarkMediumContrast = Color.argb(0xFF101510)
val surfaceBrightDarkMediumContrast = Color.argb(0xFF353A35)
val surfaceContainerLowestDarkMediumContrast = Color.argb(0xFF0A0F0B)
val surfaceContainerLowDarkMediumContrast = Color.argb(0xFF181D18)
val surfaceContainerDarkMediumContrast = Color.argb(0xFF1C211C)
val surfaceContainerHighDarkMediumContrast = Color.argb(0xFF262B26)
val surfaceContainerHighestDarkMediumContrast = Color.argb(0xFF313631)

val primaryDarkHighContrast = Color.argb(0xFFEFFFEE)
val onPrimaryDarkHighContrast = Color.argb(0xFF000000)
val primaryContainerDarkHighContrast = Color.argb(0xFF9CD9A8)
val onPrimaryContainerDarkHighContrast = Color.argb(0xFF000000)
val secondaryDarkHighContrast = Color.argb(0xFFEFFFEE)
val onSecondaryDarkHighContrast = Color.argb(0xFF000000)
val secondaryContainerDarkHighContrast = Color.argb(0xFFBBD0BC)
val onSecondaryContainerDarkHighContrast = Color.argb(0xFF000000)
val tertiaryDarkHighContrast = Color.argb(0xFFF3FCFF)
val onTertiaryDarkHighContrast = Color.argb(0xFF000000)
val tertiaryContainerDarkHighContrast = Color.argb(0xFFA6D2DD)
val onTertiaryContainerDarkHighContrast = Color.argb(0xFF000000)
val errorDarkHighContrast = Color.argb(0xFFFFF9F9)
val onErrorDarkHighContrast = Color.argb(0xFF000000)
val errorContainerDarkHighContrast = Color.argb(0xFFFFBAB1)
val onErrorContainerDarkHighContrast = Color.argb(0xFF000000)
val backgroundDarkHighContrast = Color.argb(0xFF101510)
val onBackgroundDarkHighContrast = Color.argb(0xFFDFE4DC)
val surfaceDarkHighContrast = Color.argb(0xFF101510)
val onSurfaceDarkHighContrast = Color.argb(0xFFFFFFFF)
val surfaceVariantDarkHighContrast = Color.argb(0xFF414941)
val onSurfaceVariantDarkHighContrast = Color.argb(0xFFF5FDF2)
val outlineDarkHighContrast = Color.argb(0xFFC5CDC3)
val outlineVariantDarkHighContrast = Color.argb(0xFFC5CDC3)
val scrimDarkHighContrast = Color.argb(0xFF000000)
val inverseSurfaceDarkHighContrast = Color.argb(0xFFDFE4DC)
val inverseOnSurfaceDarkHighContrast = Color.argb(0xFF000000)
val inversePrimaryDarkHighContrast = Color.argb(0xFF003216)
val surfaceDimDarkHighContrast = Color.argb(0xFF101510)
val surfaceBrightDarkHighContrast = Color.argb(0xFF353A35)
val surfaceContainerLowestDarkHighContrast = Color.argb(0xFF0A0F0B)
val surfaceContainerLowDarkHighContrast = Color.argb(0xFF181D18)
val surfaceContainerDarkHighContrast = Color.argb(0xFF1C211C)
val surfaceContainerHighDarkHighContrast = Color.argb(0xFF262B26)
val surfaceContainerHighestDarkHighContrast = Color.argb(0xFF313631)







