package cz.vojtechhoranek.site.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        cz.vojtechhoranek.site.components.layouts.MarkdownLayout("About me") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("about-me") }) {
                org.jetbrains.compose.web.dom.Text("About me")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This is yet to be written...")
            }
        }
    }
}
