package cz.vojtechhoranek.site

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.w3c.dom.Window

private const val preferredColorSchemeQuery = "(prefers-color-scheme: dark)"

val Window.preferredColorMode
    get() = window.matchMedia(preferredColorSchemeQuery)
        .let { if (it.matches) ColorMode.DARK else ColorMode.LIGHT }

@Composable
fun ColorSchemeEffect() {
    var colorMode by ColorMode.currentState

    LaunchedEffect(Unit) {
        window.matchMedia(preferredColorSchemeQuery).onchange = {
            colorMode = window.preferredColorMode
            Unit
        }
    }
}