package cz.vojtechhoranek.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaGithub
import com.varabyte.kobweb.silk.components.icons.fa.FaLinkedin
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMail
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.vojtechhoranek.site.*
import cz.vojtechhoranek.site.components.layouts.PageLayout
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {
    val sitePalette = ColorMode.current.toSitePalette()

    PageLayout("Home") {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .padding(top = 3.cssRem),
            contentAlignment = Alignment.TopStart,
        ) {
            Column(Modifier.gap(2.cssRem)) {
                SpanText(
                    text = "Developer, freelancer.",
                    modifier = Modifier
                        .typography(HeadlineLarge)
                        .fontWeight(FontWeight.Medium)
                )

                SpanText(
                    text = "I make mobile apps. Feel free to contact me.",
                    modifier = Modifier
                        .typography(TitleMedium)
                )

                Row(
                    modifier = Modifier
                        .gap(1.cssRem)
                        .flexWrap(FlexWrap.Wrap)
                ) {
                    val ctx = rememberPageContext()
                    Button(
                        onClick = {
                            ctx.router.navigateTo(
                                "mailto:vojtechhoranek@gmail.com",
                                openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                            )
                        },
                        colorScheme = PrimaryColorScheme
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.spacedBy(0.5.cssRem),
                            modifier = Modifier
                                .color(sitePalette.onPrimary),
                        ) {
                            MdiMail(style = IconStyle.OUTLINED)
                            Text("Contact")
                        }
                    }

                    Button(
                        onClick = {
                            ctx.router.navigateTo(
                                "https://github.com/vojta-horanek",
                            )
                        },
                        colorScheme = TertiaryColorScheme
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.spacedBy(0.5.cssRem),
                            modifier = Modifier
                                .color(sitePalette.onTertiary),
                        ) {
                            FaGithub()
                            Text("GitHub")
                        }
                    }

                    Button(
                        onClick = {
                            ctx.router.navigateTo(
                                "https://www.linkedin.com/in/vojtechhoranek",
                            )
                        },
                        colorScheme = TertiaryColorScheme
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.spacedBy(0.5.cssRem),
                            modifier = Modifier
                                .color(sitePalette.onTertiary),
                        ) {
                            FaLinkedin()
                            Text("LinkedIn")
                        }
                    }
                }
            }
        }
    }
}
