package cz.vojtechhoranek.site

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.*
import org.jetbrains.compose.web.css.cssRem

val Number.sp
    get() = (this.toFloat() / 16f).cssRem

sealed interface TypographyKind : ComponentKind

@Composable
fun Modifier.typography(style: CssStyleVariant<TypographyKind>) = this.then(TypographyBaseStyle.toModifier(style))

fun Modifier.singleLine() =
    this
        .textOverflow(TextOverflow.Ellipsis)
        .overflow(Overflow.Hidden)
        .whiteSpace(WhiteSpace.NoWrap)

val TypographyBaseStyle = CssStyle.base<TypographyKind> { Modifier }

val DisplayLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(57.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing((-0.25).sp)
        .lineHeight(64.sp)
}

val DisplayMedium = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(45.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(52.sp)
}

val DisplaySmall = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(36.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(44.sp)
}

val HeadlineLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(32.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(40.sp)
}

val HeadlineMedium = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(28.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(36.sp)
}

val HeadlineSmall = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(24.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(32.sp)
}

val TitleLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(22.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(28.sp)
}

val TitleMediumLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(20.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.sp)
        .lineHeight(28.sp)
}

val TitleMedium = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(16.sp)
        .fontWeight(FontWeight.Medium)
        .letterSpacing(0.15.sp)
        .lineHeight(24.sp)
}

val TitleSmall = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(14.sp)
        .fontWeight(FontWeight.Medium)
        .letterSpacing(0.1.sp)
        .lineHeight(20.sp)
}

val BodyLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(16.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.5.sp)
        .lineHeight(24.sp)
}

val BodyMedium = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(14.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.25.sp)
        .lineHeight(20.sp)
}

val BodySmall = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(12.sp)
        .fontWeight(FontWeight.Normal)
        .letterSpacing(0.4.sp)
        .lineHeight(16.sp)
}

val LabelLarge = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(14.sp)
        .fontWeight(FontWeight.Medium)
        .letterSpacing(0.1.sp)
        .lineHeight(20.sp)
}

val LabelLargeProminent = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(14.sp)
        .fontWeight(FontWeight.Bold)
        .letterSpacing(0.1.sp)
        .lineHeight(20.sp)
}

val LabelMedium = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(12.sp)
        .fontWeight(FontWeight.Medium)
        .letterSpacing(0.5.sp)
        .lineHeight(16.sp)
        .textTransform(TextTransform.None)
}

val LabelMediumProminent = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(12.sp)
        .fontWeight(FontWeight.Bold)
        .letterSpacing(0.5.sp)
        .lineHeight(16.sp)
        .textTransform(TextTransform.None)
}

val LabelSmall = TypographyBaseStyle.addVariantBase {
    Modifier
        .fontSize(11.sp)
        .fontWeight(FontWeight.Medium)
        .letterSpacing(0.5.sp)
        .lineHeight(16.sp)
}
