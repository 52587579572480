package cz.vojtechhoranek.site.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.BoxShadow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.vojtechhoranek.site.TitleMedium
import cz.vojtechhoranek.site.TitleMediumLarge
import cz.vojtechhoranek.site.components.widgets.IconButton
import cz.vojtechhoranek.site.toSitePalette
import cz.vojtechhoranek.site.typography
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text

val NavHeaderStyle = CssStyle.base {
    Modifier.fillMaxWidth().padding(1.cssRem)
}

@Composable
private fun NavLink(path: String, text: String, icon: @Composable () -> Unit) {
    Link(path, variant = UndecoratedLinkVariant.then(UncoloredLinkVariant)) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .gap(0.5.cssRem)
        ) {
            icon()
            Text(text)
        }
    }
}

@Composable
private fun MenuItems() {
    NavLink("/", "Home") {
        MdiHome(style = IconStyle.OUTLINED)
    }
    NavLink("/about", "About me") {
        MdiFace(style = IconStyle.OUTLINED)
    }
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateY(100.percent)
    }

    to {
        Modifier
    }
}

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun NavHeader() {
    Row(NavHeaderStyle.toModifier(), verticalAlignment = Alignment.CenterVertically) {
        Link("/") {
            // Block display overrides inline display of the <img> tag, so it calculates centering better
            Image(
                "/logo-big.webp", "Vojtěch Hořánek",
                modifier = Modifier
                    .height(4.cssRem)
                    .display(DisplayStyle.Block)
                    .displayIfAtLeast(Breakpoint.MD)
            )

            Image(
                "/logo.webp", "Vojtěch Hořánek",
                modifier = Modifier
                    .height(4.cssRem)
                    .display(DisplayStyle.Block)
                    .displayUntil(Breakpoint.MD)
            )
        }

        Spacer()

        Row(
            modifier = Modifier
                .gap(1.5.cssRem)
                .padding(leftRight = 1.cssRem)
                .displayIfAtLeast(Breakpoint.MD)
                .typography(TitleMediumLarge),
            verticalAlignment = Alignment.CenterVertically
        ) {
            MenuItems()
        }

        Row(
            Modifier
                .fontSize(1.5.cssRem)
                .gap(1.cssRem)
                .displayUntil(Breakpoint.MD),
            verticalAlignment = Alignment.CenterVertically
        ) {
            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }

            IconButton(
                onClick = {
                    menuState = SideMenuState.OPEN
                }
            ) {
                MdiMenu()
            }

            if (menuState != SideMenuState.CLOSED) {
                BottomMenu(
                    menuState,
                    close = { menuState = menuState.close() },
                    onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
                )
            }
        }
    }
}

@Composable
private fun BottomMenu(menuState: SideMenuState, close: () -> Unit, onAnimationEnd: () -> Unit) {
    Overlay(
        Modifier
            .setVariable(
                OverlayVars.BackgroundColor,
                ColorMode.current.toSitePalette().scrim
            )
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fillMaxWidth()
                    .align(Alignment.BottomCenter)
                    .gap(1.5.cssRem)
                    .backgroundColor(ColorMode.current.toSitePalette().surfaceContainerLow)
                    .boxShadow(
                        BoxShadow.of(
                            0.0.px, (-0.2).px, 6.0.px, 0.2.px, ColorMode.current.toSitePalette().surfaceDim
                        )
                    )
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) {
                                AnimationTimingFunction.cubicBezier(0.0, 1.0, 0.81, 1.0)
                            } else {
                                AnimationTimingFunction.cubicBezier(0.0, 1.0, 0.81, 1.0)
                            },
                            direction = if (menuState == SideMenuState.OPEN) {
                                AnimationDirection.Normal
                            } else {
                                AnimationDirection.Reverse
                            },
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 1.cssRem, topRight = 1.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                IconButton(
                    onClick = { close() },
                    modifier = Modifier
                        .margin(right = 1.cssRem, top = 1.cssRem)
                ) {
                    MdiClose()
                }

                Column(
                    modifier = Modifier
                        .padding {
                            bottom(2.cssRem)
                            leftRight(1.cssRem)
                        }
                        .gap(1.5.cssRem)
                        .typography(TitleMedium)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.Start
                ) {
                    MenuItems()
                }
            }
        }
    }
}
